import React, { useState } from "react";
import './books.css';
let book_data = require('./books.json');

export default function Books(){
	const [tags, setTags] = useState(new Set())
	let lovedText = "❤️"

	let genres = ["Adventure/Thriller",
		"Classics",
		"Fantasy",
		"Gothic",
		"Historical Fiction",
		"History/Journalism",
		"Horror",
		"Humor",
		"Informational",
		"Letters and Essays",
		"Literary Fiction",
		"Memoir/Biography",
		"Mystery/Suspense",
		"Romance",
		"Science Fiction",
		"Self Help",
		"Tech",
		lovedText]
	const getSeed = () => {
		const today = new Date();
		return today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();
	};

	// Seedable random number generator (simple implementation)
	const seededRandom = (seed) => {
		const x = Math.sin(seed) * 10000;
		return x - Math.floor(x);
	};

	const handleTagClick = (e) => {
		let clickedTag = e.target.textContent
		if (tags.has(clickedTag)) {
			// remove tag
			setTags(prev => new Set([...prev].filter(x => x !== clickedTag)))
		}
		else {
			// add tag
			setTags(previousState => new Set([...previousState, clickedTag]))
		}
  	};

	const filteredContent = book_data.filter((item) => {
		if (tags.size === 0) {
			return true;
		}
		if (tags.size === 1 && tags.has(lovedText)) {
			return item.loved;
		}
		if (tags.has(lovedText)) {
			return item.genre.some((tag) => tags.has(tag)) && item.loved;
		}
		return item.genre.some((tag) => tags.has(tag));
	});

	// sorts by lastName, firstName and title
	const sorted = filteredContent.sort((a, b) => {
		if (a.lastName < b.lastName) {
			return -1;
		}
		else if (a.lastName > b.lastName) {
			return 1;
		}
		else {
			if (a.firstName < b.firstName) {
				return -1;
			}
			else if (a.firstName > b.firstName) {
				return 1;
			}
			else {
				if (a.title < b.title) {
					return -1;
				}
				else if (a.title > b.title) {
					return 1;
				}
				else {
					return 0;
				}

			}
		}
	});

	let fiction = sorted.filter(entry => entry["fiction"] === true)
	let nonfics = sorted.filter(entry => entry["fiction"] === false)


	let good_fiction = book_data.filter(entry => entry["fiction"] && entry["rating"] >= "3.5" && entry["title"])
	let good_nonfics = book_data.filter(entry => !entry["fiction"] && entry["rating"] >= "3.5")

	const seed = getSeed();
	let fic_rec = good_fiction[Math.floor(seededRandom(seed) * good_fiction.length)]
	let non_rec = good_nonfics[Math.floor(seededRandom(seed) * good_nonfics.length)]

	return (
		<div>
		<div className="books" style={{width: "100%", maxWidth: "850px", margin: "auto"}}>

			<br/>
			<h1 className="cursive">Boooooooooks</h1>
			<p>
				Check out my personal library featuring books I've recently read!
				I've highlighted books I particularly enjoyed in bold and have provided a rating out of five stars for each book.
				Click on genres to filter your search, and hit the heart icon to only display my favorites.
				<br/>
				<br/>

				Books of the day (&ge; 3.5 stars)!
				<ul>
					<li> Fiction: <em>{fic_rec?.["title"]}</em> by {fic_rec?.["firstName"] + " " + fic_rec?.["lastName"]} </li>
					<li> Nonfiction: <em> {non_rec?.["title"]}</em> by {non_rec?.["firstName"] + " " + non_rec?.["lastName"]} </li>
				</ul>
				Happy reading!
			</p>


			<p>
			{genres.map(genre => <button key={genre} onClick={handleTagClick} className={tags.has(genre) ? "selected cursive" : "cursive"}>{genre}</button>)}
			</p>

			<h2>Fiction</h2>
			<ul>
			{
				fiction.map(entry => <li key={entry["title"] + entry["lastName"]}>
					{entry["lastName"]}, {entry["firstName"]}: <span style={{fontWeight: entry["loved"] ? "bold" : "normal"}}><em>{entry["title"]}</em></span> {entry["additional"]}{' '}
					<span style={{whiteSpace: "nowrap"}}>({entry["rating"]} ★)</span>
					</li>)
			}
			</ul>

			<h2>Nonfiction</h2>
			<ul>
			{
				nonfics.map(entry => <li key={entry["title"]}>
					{entry["lastName"]}, {entry["firstName"]}: <span style={{fontWeight: entry["loved"] ? "bold" : "normal"}}><em>{entry["title"]}</em></span>{' '}
					<span style={{whiteSpace: "nowrap"}}>({entry["rating"]} ★)</span>

					</li>)
			}
			</ul>
			<br/>


		</div>
			<br/>
		</div>
	);
}
